@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');


:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  font-family: 'YourCustomFont' !important;
  font-size: 20px !important;
}

body {
 background-color: #000000 !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/KGRedHands.ttf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}

.desk{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  background-image: url('./gatogif.gif')!important;
  background-color: #000 !important ;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}

.mob{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  background-image: url('./gatogif.gif')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-position: center;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}
.under{
  background-color: #b1008e43;
}
.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
}


.image_anos{
  width: 100%;
  border-radius: 50px;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 100%;
    border-radius: 50px;
  }

      }



.anos_text{
  font-size: 3.8rem !important;
  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(151, 0, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(166, 19, 0);
}


@media screen and (min-width: 1000px){
  .anos_text{
    font-size: 9rem !important;
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
  }
  
}




.anon_p{

   font-size: 17px !important;
  color: rgb(255, 255, 255);
  font-family: Courier New,Courier,monospace;
 
}


@media screen and (min-width: 1000px){
  .anon_p{
 
    font-size: 27px !important;
  color: rgb(86, 0, 107);
    font-family: Courier New,Courier,monospace;
   
  }
  
}

.image_anos_uni{
  width: 100%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 95%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
  }
  
}




.card {
    position: relative;
    width: auto;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
}






























@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px
}

.card {
  position: relative;
  width: auto;
  height: 100%;
  background-color: #a4018625 !important;
  border-radius: 8px
}

.card-content {
  padding: 20px
}

.card-title {
  margin: 0;
  font-size: 24px
}

.card-description {
  margin: 10px 0
}

.card-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #ff0400;
  color: #fff;
  text-decoration: none;
  border-radius: 4px
}

.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);

  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #070707a8 !important;
}


/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #5a41fea9 !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}


















.button {
  position: relative;
  background-color: rgb(230, 34, 77);
  border-radius: 5px;
  box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
  padding: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  box-sizing: border-box;
  width: 154px;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.button::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 700ms ease-in-out;
  display: inline-block;
}

.button:hover::before {
  width: 100%;
}




.anos_textf{
  font-size: 2rem !important;
  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
}


@media screen and (min-width: 1000px){
  .anos_textf{
    font-size: 5rem !important;
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(201, 171, 4), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(215, 252, 29), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(215, 194, 60), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(119, 148, 1), 0 0 16px rgb(246, 230, 0);
  }
  
}



.comm{
  font-size: 100px !important;
  color: #000000;
}








.anos_textdg{
  font-size: 2rem !important;
  color: rgb(251, 251, 251);
  text-align: start !important;
  font-family: Courier New,Courier,monospace;
  
  
  text-shadow: 0 0 2px rgb(254, 216, 0), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(212, 184, 0), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(232, 202, 5), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(231, 193, 2), 0 0 16px rgb(246, 230, 0);
}


@media screen and (min-width: 1000px){
  .anos_textdg{
    font-size: 5rem !important;
    text-align: start !important;
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(254, 216, 0), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(212, 184, 0), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(232, 202, 5), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(231, 193, 2), 0 0 16px rgb(246, 230, 0);
  }
  
}


.anos_textdglogo{
  font-size: 3rem !important;
  color: rgb(251, 251, 251);
  text-align: start !important;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(254, 216, 0), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(212, 184, 0), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(232, 202, 5), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(231, 193, 2), 0 0 16px rgb(246, 230, 0);
}

@media screen and (min-width: 1000px){
  .anos_textdglogo{
    font-size: 2.6rem !important;
    color: rgb(251, 251, 251);
    text-align: start !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(254, 216, 0), 0 0 4px rgb(239, 198, 64), 0 0 6px rgb(212, 184, 0), 0 0 8px rgb(213, 192, 0), 0 0 10px rgb(232, 202, 5), 0 0 12px rgb(143, 0, 0), 0 0 14px rgb(231, 193, 2), 0 0 16px rgb(246, 230, 0);
  }
  
}


.socials{
  font-size: 40px !important;
  color: #000 !important;
}


























































































.buttonm {
  position: relative;
  background-color: rgb(230, 34, 77);
  border-radius: 5px;
  box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
  padding: 13px;
  background-repeat: no-repeat;
  cursor: pointer;
  box-sizing: border-box;
  width: 200px !important;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.buttonm::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 700ms ease-in-out;
  display: inline-block;
}

.buttonm:hover::before {
  width: 100%;
}

